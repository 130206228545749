import React from "react"
import BusinessRegistration from "../../../components/body/pages/en-ng/business/registration-cac-tin"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const BusinessRegistrationPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/registration-cac-tin"}
      title="Get Your CAC & TIN | Register Your Business | Kuda Business"
      description="With Kuda Business all-in-one business manager it's quick & easy to register your business. Be an official, trustworthy business eligible for loans & grants."
    />
    <BusinessRegistration />
  </Layout>
)

export default BusinessRegistrationPage
